<template>
  <div class="invoice-edit">
    <page-nav :title="isEdit ? '发票模板编辑' : '新增发票模板'" />
    <van-form @submit="onSubmit" validate-trigger="onSubmit">
      <van-field
        v-model="titleTypeText"
        name="titleType"
        is-link
        readonly
        label="抬头类型"
        input-align="right"
        @click="showTitleTypeModal = true"
      />
      <van-field
        v-model="title"
        name="title"
        label="发票抬头"
        placeholder="请输入"
        input-align="right"
        maxlength="50"
        :rules="[{ required: true, message: '请输入发票抬头' }]"
      />
      <van-field
        v-model="invoiceTypeText"
        name="invoiceType"
        is-link
        readonly
        label="发票类型"
        input-align="right"
        @click="showInvoiceTypeModal = true"
      />
      <van-field
        v-model="taxNumber"
        name="taxNumber"
        label="纳税人识别号"
        placeholder="请输入"
        input-align="right"
        maxlength="50"
        :rules="[{ required: titleType == 1, message: '请输入纳税人识别号' }]"
      />
      <van-field
        v-model="bankName"
        name="bankName"
        label="开户银行名称"
        placeholder="请输入"
        input-align="right"
        maxlength="50"
        :rules="[{ required: titleType == 1, message: '请输入开户银行名称' }]"
      />
      <van-field
        v-model="cardNumber"
        name="cardNumber"
        label="基本开户账号"
        placeholder="请输入"
        input-align="right"
        maxlength="50"
        :rules="[{ required: titleType == 1, message: '请输入基本开户账号' }]"
      />
      <van-field
        name="invoiceAddress"
        v-model="invoiceAddress"
        label="注册场所地址"
        placeholder="请输入"
        input-align="right"
        maxlength="50"
        :rules="[{ required: titleType == 1, message: '请输入注册场所地址' }]"
      />
      <van-field
        name="invoicePhone"
        v-model="invoicePhone"
        label="注册固定电话"
        placeholder="请输入"
        input-align="right"
        maxlength="50"
        :rules="[{ required: titleType == 1, message: '请输入注册固定电话' }]"
      />
      <div class="actions">
        <van-button v-if="isEdit && showDefault" block round type="primary" color="#999" native-type="button" @click="onSetDefault">设为默认发票</van-button>
        <van-button block round type="info" native-type="submit">保存</van-button>
        <van-button v-if="isEdit" block round type="danger" native-type="button" @click="onDelete">删除</van-button>
      </div>
    </van-form>
    <van-action-sheet v-model="showTitleTypeModal" :actions="titleTypeActions" @select="onSelectTitleType" />
    <van-action-sheet v-model="showInvoiceTypeModal" :actions="invoiceTypeActions" @select="onSelectInvoiceType" />
  </div>
</template>

<script>
import Vue from 'vue';
import utils from '../../common/utils';
import { Toast, Form, Field, Dialog, ActionSheet } from 'vant';
import Fetch from '@/common/fetch';
import { AddInvoice, ModifyInvoice, DeleteInvoice, InvoiceSetDefault, GetInvoiceById } from '@/common/interface';
import PageNav from '@/components/PageNav.vue';
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(ActionSheet);

export default {
  name: 'InvoiceEdit',
  components: { PageNav },
  data() {
    return {
      titleType: 0,
      title: '',
      invoiceType: 0,
      taxNumber: '',
      bankName: '',
      cardNumber: '',
      invoiceAddress: '',
      invoicePhone: '',
      isEdit: false,
      showDefault: false,
      showTitleTypeModal: false,
      titleTypeActions: [
        { name: '企业' },
        { name: '个人' },
      ],
      showInvoiceTypeModal: false,
      invoiceTypeActions: [
        { name: '增值税普通发票' },
        { name: '增值税专用发票' },
      ],
    };
  },
  computed: {
    titleTypeText: {
      get() {
        if (this.titleType == 1) {
          return '企业';
        } else if (this.titleType == 2) {
          return '个人';
        } else {
          return '请选择抬头类型';
        }
      },
      set() { 
      },
    },
    invoiceTypeText: {
      get() {
        if (this.invoiceType == 1) {
          return '增值税普通发票';
        } else if (this.invoiceType == 2) {
          return '增值税专用发票';
        } else {
          return '请选择发票类型';
        }
      },
      set(){},
    },
  },
  created() {
    if (utils.getQueryString('id')) {
      this.isEdit = true;
      this.fetchEditDetail();
    } 
  },
  methods: {
    fetchEditDetail() {
      const id = utils.getQueryString('id');
      Fetch.post(GetInvoiceById, {id}).then(res => {
        this.titleType = res.titleType;
        this.invoiceType = res.invoiceType;
        this.title = res.title;
        this.bankName = res.bankName;
        this.cardNumber = res.cardNumber;
        this.invoiceAddress = res.invoiceAddress;
        this.taxNumber = res.taxNumber;
        this.invoicePhone = res.invoicePhone;
        this.showDefault = res.isDefault != 1;
      }).catch((err) => {
        Toast(err.msg);
      })
    },

    onSelectTitleType(item, index) {
      this.titleType = index + 1;
      this.titleTypeText = item.name;
      this.showTitleTypeModal = false;
    },

    onSelectInvoiceType(item, index) {
      this.invoiceType = index + 1;
      this.invoiceTypeText = item.name;
      this.showInvoiceTypeModal = false;
    },

    onSetDefault() {
      const id = utils.getQueryString('id') || '';
      const params = {
        id,
      };
      Fetch.post(InvoiceSetDefault,params).then(() => {
        this.$router.go(-1);
      }).catch(err => {
        Toast(err.msg || '请求失败')
      })
    },

    onDelete() {
      Dialog.confirm({
        title: '确认要删除此发票模板吗？',
        confirmButtonText: '删除',
      })
      .then(() => {
        const id = utils.getQueryString('id') || '';
        Fetch.post(DeleteInvoice, {id}).then(() => {
          this.$router.go(-1);
        }).catch((err) => {
          Toast(err.msg || '请求失败');
        });
      }).catch(() => {
      });
    },

    onSubmit(values) {
      if (this.titleType == 0) {
        Toast('请选择抬头类型');
        return;
      }
      if (this.invoiceType == 0) {
        Toast('请选择发票类型');
        return;
      }
      const params = {
        ...values,
        titleType: this.titleType,
        invoiceType: this.invoiceType,
      };
      let api = AddInvoice;
      const id = utils.getQueryString('id') || '';
      // 有id为编辑
      if (id) {
        params.id = id;
        api = ModifyInvoice;
      }
      Fetch.post(api, params).then(() => {
        this.$router.go(-1);
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.invoice-edit {
  .actions {
    margin: 20px 0;
    padding: 0 16px;
    button {
      margin-bottom: 8px;
    }
  }
}
</style>